import { Theme } from 'app/ui-kit/theme/Theme';

export interface IInputSelectBtnProps {
  label?: string;
  options: { value: any; label: string }[];
  feedback?: string;
  classes?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  required?: boolean;
  readOnly?: boolean;
}

export default function InputSelectBtn(props: IInputSelectBtnProps) {
  const { label, options, feedback, classes, value, onChange, required, readOnly } = props;
  return (
    <div className={`${classes}`}>
      <select
        className="btn btn-text text-primary p-0 text-start"
        aria-label="Floating label select example"
        value={value}
        onChange={onChange}
        defaultValue={undefined}
        required={required}
        disabled={readOnly}
      >
        <option selected className="fw-bolder">
          {label}
        </option>
        {options.map((option, i) => (
          <option key={i} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}
