import { Divider, Tooltip } from 'antd';
import { Calendar } from 'app/components/Calender/Calender';
import Container from 'app/components/Container/Container';
import Avatar from 'app/components/Icons/Avatar';
import { PageHeader } from 'app/components/PageHeader';
import { FormControl } from 'app/components/v1/Input';
import { Select } from 'app/components/v1/Select';
import { classApi } from 'app/services/class';
import { meetingAccountApi } from 'app/services/meeting-account';
import { studentApi } from 'app/services/student';
import { subjectApi } from 'app/services/subject';
import { teacherApi } from 'app/services/teacher';
import { UserType } from 'app/services/types';
import { yearsApi } from 'app/services/years';
import { MAIN_SELECTORS } from 'app/store';
import { splitTextBy } from 'app/utils/utils';
import _ from 'lodash';
import qs from 'qs';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const TimetableStyled = styled(Container)`
  padding: 50px 30px;
  overflow-x: scroll;
`;

export default function Timetable(props: { calendarType?: 'lessonSchedule' }) {
  const user = useSelector(MAIN_SELECTORS.user);
  const isParent = useMemo(
    () => user?.entity?.id && [UserType.Parent?.toLowerCase()].includes(user?.userType?.toLowerCase()!),
    [user?.userType, user?.entity?.id]
  );
  const isAdmin = useMemo(
    () => user?.entity?.id && [UserType.Admin?.toLowerCase()].includes(user?.userType?.toLowerCase()!),
    [user?.userType, user?.entity?.id]
  );

  const isTeacher = useMemo(
    () => user?.entity?.id && [UserType.Teacher?.toLowerCase()].includes(user?.userType?.toLowerCase()!),
    [user?.userType, user?.entity?.id]
  );

  const { data: studentData, isLoading: isLoadingStudents } = studentApi.useGetStudentsQuery(
    {
      requestQuery: qs.stringify(
        {
          filters: {
            id: {
              $in: user.familyLinks?.students
            }
          }
        },
        {
          encodeValuesOnly: true
        }
      )
    },
    {
      skip: !user.familyLinks?.students?.length || !isParent || props.calendarType === 'lessonSchedule'
    }
  );

  const [selectedIds, setSelectedIds] = useState<{
    studentIds: string[];
    teacherIds: string[];
    classIds: string[];
    meetingAccountIds: string[];
    subjectIds: string[];
    yearIds: string[];
  }>();

  const [searchQuery, setSearchQuery] = useState<{
    teacher?: string;
    class?: string;
    meetingAccount?: string;
    student?: string;
    year?: string;
    subject?: string;
  }>();

  const { data: teachersData, isLoading: isLoadingTeachers } = teacherApi.useGetTeachersQuery(
    {
      requestQuery: qs.stringify(
        {
          ...(searchQuery?.teacher
            ? {
                filters: {
                  ...(splitTextBy(searchQuery?.teacher, ' ')?.length == 1 &&
                  splitTextBy(searchQuery?.teacher, ' ')?.at(0) != ''
                    ? {
                        $or: [
                          {
                            name: {
                              $containsi: splitTextBy(searchQuery?.teacher, ' ')
                            }
                          },
                          {
                            lastName: {
                              $containsi: splitTextBy(searchQuery?.teacher, ' ')
                            }
                          },
                          {
                            teacherId: {
                              $containsi: splitTextBy(searchQuery?.teacher, ' ')
                            }
                          }
                        ]
                      }
                    : splitTextBy(searchQuery?.teacher, ' ').length > 1
                    ? {
                        $and: [
                          {
                            name: {
                              $containsi: splitTextBy(searchQuery?.teacher, ' ')
                            },
                            lastName: {
                              $containsi: splitTextBy(searchQuery?.teacher, ' ')
                            }
                          }
                        ]
                      }
                    : {})
                }
              }
            : {})
        },
        {
          encodeValuesOnly: true
        }
      )
    },
    {
      skip: !user?.entity?.id || !isAdmin || props.calendarType !== 'lessonSchedule'
    }
  );

  const { data: classroomsData, isLoading: isLoadingClassrooms } = classApi.useGetClasseroomsQuery(
    {
      requestQuery: qs.stringify(
        {
          ...(searchQuery?.class
            ? {
                filters: {
                  $or: [
                    {
                      classId: {
                        $containsi: searchQuery?.class
                      }
                    },
                    {
                      subject: {
                        name: {
                          $containsi: searchQuery?.class
                        }
                      }
                    },
                    {
                      year: {
                        shortName: {
                          $containsi: searchQuery?.class
                        }
                      }
                    },
                    {
                      year: {
                        name: {
                          $containsi: searchQuery?.class
                        }
                      }
                    }
                  ]
                }
              }
            : {}),
          populate: ['subject.parent_subject', 'year']
        },
        {
          encodeValuesOnly: true
        }
      )
    },
    {
      skip: !user?.entity?.id || !isAdmin || props.calendarType !== 'lessonSchedule'
    }
  );

  const { data: meetingAccountsData, isLoading: isLoadingMeetingAccounts } =
    meetingAccountApi.useFindManyMeetingAccountQuery(
      {
        ...(searchQuery?.meetingAccount
          ? {
              filters: {
                name: {
                  $containsi: searchQuery?.meetingAccount
                }
              }
            }
          : {})
      },
      {
        skip: !user?.entity?.id || !isAdmin || props.calendarType !== 'lessonSchedule'
      }
    );

  const { data: studentsAllData, isLoading: isLoadingAllStudents } = studentApi.useGetStudentsQuery(
    {
      requestQuery: qs.stringify(
        {
          ...(searchQuery?.student
            ? {
                filters: {
                  ...(splitTextBy(searchQuery?.student, ' ')?.length == 1 &&
                  splitTextBy(searchQuery?.student, ' ')?.at(0) != ''
                    ? {
                        $or: [
                          {
                            name: {
                              $containsi: splitTextBy(searchQuery?.student, ' ')
                            }
                          },
                          {
                            lastName: {
                              $containsi: splitTextBy(searchQuery?.student, ' ')
                            }
                          },
                          {
                            studentId: {
                              $containsi: splitTextBy(searchQuery?.student, ' ')
                            }
                          }
                        ]
                      }
                    : splitTextBy(searchQuery?.student, ' ').length > 1
                    ? {
                        $and: [
                          {
                            name: {
                              $containsi: splitTextBy(searchQuery?.student, ' ')
                            },
                            lastName: {
                              $containsi: splitTextBy(searchQuery?.student, ' ')
                            }
                          }
                        ]
                      }
                    : {})
                }
              }
            : {})
        },
        {
          encodeValuesOnly: true
        }
      )
    },
    {
      skip: !user?.entity?.id || !isAdmin || props.calendarType !== 'lessonSchedule'
    }
  );

  const { data: subjectsData, isLoading: isLoadingSubjectsData } = subjectApi.useGetSubjectsQuery(
    {
      requestQuery: qs.stringify(
        {
          ...(searchQuery?.subject
            ? {
                filters: {
                  $or: [
                    {
                      name: {
                        $containsi: searchQuery?.subject
                      }
                    }
                  ]
                }
              }
            : {}),
          populate: ['parent_subject']
        },
        {
          encodeValuesOnly: true
        }
      )
    },
    {
      skip: !user?.entity?.id || !isAdmin || props.calendarType !== 'lessonSchedule'
    }
  );

  const { data: yearsData, isLoading: isLoadingYearsData } = yearsApi.useGetYearsQuery(
    {
      requestQuery: qs.stringify(
        {
          ...(searchQuery?.year
            ? {
                filters: {
                  $or: [
                    {
                      name: {
                        $containsi: searchQuery?.year
                      }
                    },
                    {
                      shortName: {
                        $containsi: searchQuery?.year
                      }
                    }
                  ]
                }
              }
            : {})
        },
        {
          encodeValuesOnly: true
        }
      )
    },
    {
      skip: !user?.entity?.id || !isAdmin || props.calendarType !== 'lessonSchedule'
    }
  );

  return (
    <TimetableStyled
      className="d-flex flex-column "
      isLoading={
        isLoadingStudents ||
        isLoadingTeachers ||
        isLoadingClassrooms ||
        isLoadingMeetingAccounts ||
        isLoadingTeachers ||
        isLoadingAllStudents
      }
    >
      <div className="d-flex flex-row">
        <PageHeader title="Timetable" />
        <h1 className="col">Timetable</h1>
        {[UserType.Parent?.toLowerCase()].includes(user?.userType?.toLowerCase()) ? (
          <div className="d-flex flex-row align-items-center gap-3 col-3">
            <span className="title">Timetables viewed:</span>
            <FormControl className="col">
              <Select
                options={studentData?.data?.map((student) => {
                  return {
                    label: (
                      <div className=" d-flex flex-row align-items-center gap-2">
                        <Avatar name={student?.attributes?.avatarImageName} width={20} height={20} />
                        {student?.attributes?.name}
                      </div>
                    ),
                    value: student?.id,
                    avatarImageName: student?.attributes?.avatarImageName
                  };
                })}
                value={selectedIds?.studentIds?.length ? selectedIds?.studentIds : null}
                onChange={(options: any) => {
                  setSelectedIds({
                    studentIds: options
                  } as any);
                }}
                mode="multiple"
              />
            </FormControl>
          </div>
        ) : [UserType.Admin?.toLowerCase()].includes(user?.userType?.toLowerCase()) &&
          props.calendarType === 'lessonSchedule' ? (
          <>
            <div className="d-flex flex-row align-items-center gap-3 w-100 ms-10">
              <FormControl className="col">
                <Tooltip
                  title={
                    selectedIds?.classIds?.length
                      ? 'You cannot filter by subject and year when filtering by classes'
                      : null
                  }
                >
                  <Select
                    name="Subjects"
                    options={subjectsData?.data?.map((e) => {
                      return {
                        label: `${e?.attributes?.name ?? ''}`,
                        value: e?.id
                      };
                    })}
                    showSearch
                    disabled={!!selectedIds?.classIds?.length}
                    tagColor="#16a4a9"
                    value={selectedIds?.subjectIds?.length ? selectedIds?.subjectIds : null!}
                    filterOption={false}
                    onSearch={(e) => {
                      setSearchQuery({
                        ...searchQuery,
                        subject: e
                      });
                    }}
                    onChange={(options: any) => {
                      setSelectedIds({
                        ...selectedIds,
                        subjectIds: options ? [options] : []
                      } as any);
                    }}
                  />
                </Tooltip>
              </FormControl>
              <FormControl className="col">
                <Tooltip
                  title={
                    selectedIds?.classIds?.length
                      ? 'You cannot filter by subject and year when filtering by classes'
                      : null
                  }
                >
                  <Select
                    name="Years"
                    options={yearsData?.data?.map((e) => {
                      return {
                        label: `${e?.attributes?.name ?? ''}`,
                        value: e?.id
                      };
                    })}
                    disabled={!!selectedIds?.classIds?.length}
                    tagColor="#93bf1a"
                    value={selectedIds?.yearIds?.length ? selectedIds?.yearIds : null!}
                    filterOption={false}
                    onSearch={(e) => {
                      setSearchQuery({
                        ...searchQuery,
                        year: e
                      });
                    }}
                    showSearch
                    onChange={(options: any) => {
                      setSelectedIds({
                        ...selectedIds,
                        yearIds: options ? [options] : []
                      } as any);
                    }}
                  />
                </Tooltip>
              </FormControl>
              <FormControl className="col">
                <Select
                  name="Teachers"
                  options={teachersData?.data?.map((e) => {
                    return {
                      label: `${e?.attributes?.name ?? ''} ${e?.attributes?.lastName ?? ''} (${
                        e?.attributes?.teacherId
                      })`,
                      value: e?.id
                    };
                  })}
                  tagColor="#007c00"
                  value={selectedIds?.teacherIds?.length ? selectedIds?.teacherIds : null!}
                  filterOption={false}
                  onSearch={(e) => {
                    setSearchQuery({
                      ...searchQuery,
                      teacher: e
                    });
                  }}
                  onChange={(options: any) => {
                    setSelectedIds({
                      ...selectedIds,
                      teacherIds: options
                    } as any);
                  }}
                  mode="multiple"
                />
              </FormControl>

              <FormControl className="col">
                <Tooltip
                  title={
                    !!selectedIds?.subjectIds?.length || !!selectedIds?.yearIds?.length
                      ? 'You cannot filter by classes when filtering by subject and year'
                      : null
                  }
                >
                  <Select
                    name="Classrooms"
                    options={classroomsData?.data?.map((e) => {
                      return {
                        label: `${e?.attributes?.classId} ${e?.attributes?.year?.data?.attributes.shortName} ${
                          e?.attributes?.subject?.data?.attributes?.name ?? ''
                        } ${
                          e?.attributes?.subject?.data?.attributes?.parent_subject.data?.attributes?.name ?? ''
                        } (${_.capitalize(e?.attributes?.type)})`,
                        value: e?.id
                      };
                    })}
                    disabled={!!selectedIds?.subjectIds?.length || !!selectedIds?.yearIds?.length}
                    filterOption={false}
                    onSearch={(e) => {
                      setSearchQuery({
                        ...searchQuery,
                        class: e
                      });
                    }}
                    tagColor="#FF6500"
                    value={selectedIds?.classIds?.length ? selectedIds?.classIds : null}
                    onChange={(options: any) => {
                      setSelectedIds({
                        ...selectedIds,
                        classIds: options
                      } as any);
                    }}
                    mode="multiple"
                  />
                </Tooltip>
              </FormControl>

              <FormControl className="col">
                <Select
                  name="Zoom Accounts"
                  options={meetingAccountsData?.data?.map((e) => {
                    return {
                      label: e?.attributes?.name,
                      value: e?.id
                    };
                  })}
                  tagColor="#2D8CFF"
                  value={selectedIds?.meetingAccountIds?.length ? selectedIds?.meetingAccountIds : null!}
                  filterOption={false}
                  onSearch={(e) => {
                    setSearchQuery({
                      ...searchQuery,
                      meetingAccount: e
                    });
                  }}
                  onChange={(options: any) => {
                    setSelectedIds({
                      ...selectedIds,
                      meetingAccountIds: options
                    } as any);
                  }}
                  mode="multiple"
                />
              </FormControl>
              <FormControl className="col">
                <Select
                  name="Students"
                  options={studentsAllData?.data?.map((e) => {
                    return {
                      label: `${e?.attributes?.name ?? ''} ${e?.attributes?.lastName ?? ''} (${
                        e?.attributes?.studentId
                      })`,
                      value: e?.id
                    };
                  })}
                  tagColor="#C68FE6"
                  value={selectedIds?.studentIds?.length ? selectedIds?.studentIds : null}
                  filterOption={false}
                  onSearch={(e) => {
                    setSearchQuery({
                      ...searchQuery,
                      student: e
                    });
                  }}
                  onChange={(options: any) => {
                    setSelectedIds({
                      ...selectedIds,
                      studentIds: options
                    } as any);
                  }}
                  mode="multiple"
                />
              </FormControl>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <Divider />
      <Calendar
        entityId={user?.entity?.id}
        entityType={user?.userType}
        calenderType={props.calendarType === 'lessonSchedule' ? 'lessonSchedule' : null!}
        filters={selectedIds}
        onlyStatuses={isTeacher ? ['active', 'trial'] : []}
        showEventPopup={isTeacher || (isAdmin && props.calendarType === 'lessonSchedule')}
      />
    </TimetableStyled>
  );
}
