import { message } from 'antd';
import { File } from 'app/components/v1/FileUploader';
import { uploadApi } from 'app/services/upload';
import { convertInputFileListToFile, download, getFileUrl } from 'app/utils/utils';
import _ from 'lodash';
import { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import styled from 'styled-components';
import { IconComponents } from '../Icons';
import { Button } from '../v1/Button';

// TODO: add the same functionality from the
// file uploader here there functions (save and delete ) you can use shared hook

const FilePreviewStyled = styled.div`
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  width: 100%;
  min-height: 600px;
  height: 100%;
  position: relative;
  .header {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 48px;
    background: #f7f9fc;
    border-radius: 10px 10px 0px 0px;
    .title {
      position: absolute;
      left: 30px;
      font-weight: 500;
      font-size: 0.875rem;
      color: #696974;
      max-width: 200px;
    }
    & > .controls {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .image-container {
    overflow: auto;
    width: 100%;
    height: 100%;
    display: grid;
    place-content: center;
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      max-height: 90%;
      max-width: 100%;
      padding: 10px;
    }
  }
  .pagination {
    position: absolute;
    left: 26px;
    bottom: 26px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #f7f9fc;
    border-radius: 10px;
    width: fit-content;
    height: fit-content;

    & > .length {
      font-weight: 500;
      font-size: 16px;
      color: #44444f;
    }
  }
  & > .controls {
    position: absolute;
    right: 26px;
    bottom: 26px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .zoom-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    height: fit-content;
    background: #f7f9fc;
    border-radius: 10px;
    .divider {
      border: 1px solid #e2e2ea;
      height: 30px;
      display: inline-block;
    }
  }
  Button[disabled] {
    background: #f7f9fc;
  }
`;

const FilePreviewButtonStyled = styled(Button)`
  width: fit-content;
  height: fit-content;
  background: transparent;
  padding: 15px;
  margin: 0;
  background: #f7f9fc !important;
`;

const AddButtonStyled = styled.label`
  background: #4c54a0;
  border-radius: 10px;
  padding: 12px;
  margin-left: 26px;
  svg {
    fill: white;
    stroke: white;
  }
`;

export const FilePreview = forwardRef((props: FilePreviewProps, ref) => {
  const [zoom, setZoom] = useState(1);
  const [selectedFileIndex, setSelectedFileIndex] = useState(0);
  const [uploadFile] = uploadApi.endpoints.uploadFile.useMutation();
  const selectedFile = useMemo<File | undefined>(() => {
    return props.files.at(selectedFileIndex);
  }, [selectedFileIndex, props.files]);
  const addFile = useCallback(
    async (e: any) => {
      const inputFiles = e.target.files;
      if (!inputFiles?.length) return;
      const files = await convertInputFileListToFile(inputFiles);
      props.onChange && props.onChange(props.files.concat(...files));
      setSelectedFileIndex(0);
    },
    [props.files]
  );
  const deleteFile = useCallback(() => {
    props.onChange &&
      props.onChange(props.files.filter((file) => file.id?.toString() !== selectedFile?.id?.toString()));
    setSelectedFileIndex(0);
  }, [props.files, selectedFile]);

  useImperativeHandle(
    ref,
    () => {
      return {
        async save() {
          try {
            const savedFiles: File[] = [];
            for (const file of props.files) {
              if (file.file) {
                const form = new FormData();
                form.append('files', file.file);
                props.path && form.append('path', props.path);
                const res: any = await uploadFile(form);
                file.id = res?.data?.at(0)?.id;
                file.key = res?.data?.at(0)?.id;
                file.url = getFileUrl(res);
              }
              savedFiles.push(_.cloneDeep(file));
            }
            return savedFiles;
          } catch (error) {
            message.error("Couldn't upload the files");
          }
        },
        hasFiles() {
          return !!props.files?.length;
        }
      } as FilePreviewRef;
    },
    [props.files, selectedFile]
  );
  return (
    <FilePreviewStyled>
      <div className="header">
        <h1 className="title">{selectedFile?.name}</h1>
        <div className="controls  d-flex flex-row justify-content-end col-12">
          <FilePreviewButtonStyled disabled={!selectedFile} className="download" name="">
            <a href={selectedFile?.url} download={selectedFile?.name} target="_blank" rel="noreferrer">
              <IconComponents.DownloadIconComponent />
            </a>
          </FilePreviewButtonStyled>
          {props.enableDelete && (
            <FilePreviewButtonStyled
              disabled={!selectedFile}
              className="delete"
              name=""
              buttonType="text"
              color="secondary"
              icon={<IconComponents.DeleteIconComponent />}
              onClick={deleteFile}
            ></FilePreviewButtonStyled>
          )}
        </div>
      </div>
      <div className="image-container">
        {selectedFile && selectedFile.type?.includes('image') ? (
          <div
            style={{
              transform: `scale(${zoom}) translate(${(zoom > 1 ? zoom : 0) * 15}%, ${(zoom > 1 ? zoom : 0) * 30}%)`
            }}
          >
            <img src={selectedFile?.url} alt={selectedFile?.name} />
          </div>
        ) : selectedFile && !selectedFile.type?.includes('image') ? (
          <div className="w-100 h-100 d-flex justify-content-center align-items-center">
            <Button
              color="primary"
              onClick={async () => {
                await download(selectedFile.url, selectedFile.name);
              }}
            >
              <IconComponents.DownloadIconComponent />
              Download Paper
            </Button>
          </div>
        ) : (
          <h2>No Files</h2>
        )}
      </div>
      <div className="pagination">
        <FilePreviewButtonStyled
          name=""
          disabled={!selectedFile}
          onClick={() => selectedFileIndex > 0 && setSelectedFileIndex((state) => --state)}
        >
          <IconComponents.ChevronLeftIconComponent />
        </FilePreviewButtonStyled>

        <span className="length">
          {selectedFile ? selectedFileIndex + 1 : 0} / {props?.files?.length}
        </span>
        <FilePreviewButtonStyled
          name=""
          disabled={!selectedFile}
          onClick={() => selectedFileIndex < props.files.length - 1 && setSelectedFileIndex((state) => ++state)}
        >
          <IconComponents.ChevronRightIconIconComponent />
        </FilePreviewButtonStyled>
      </div>
      <div className="controls">
        <div className="zoom-controls">
          <FilePreviewButtonStyled
            name=""
            disabled={!selectedFile}
            onClick={() => zoom < 10 && setZoom((state) => ++state)}
          >
            <IconComponents.ZoomInIconComponent />
          </FilePreviewButtonStyled>
          <span className="divider"></span>
          <FilePreviewButtonStyled
            name=""
            disabled={!selectedFile}
            onClick={() => zoom > 1 && setZoom((state) => --state)}
          >
            <IconComponents.ZoomOutIconComponent />
          </FilePreviewButtonStyled>
        </div>
        {props.enableAdd && (
          <AddButtonStyled>
            <IconComponents.PlusIconComponent />
            <input type="file" disabled={!selectedFile} style={{ display: 'none' }} onChange={addFile} name="" id="" />
          </AddButtonStyled>
        )}
      </div>
    </FilePreviewStyled>
  );
});

export type FilePreviewProps = {
  files: File[];
  enableDelete?: boolean;
  path?: string;
  enableAdd?: boolean;
  onChange?: (files: File[]) => void;
};

export type FilePreviewRef = {
  save: () => Promise<File[]>;
  hasFiles: () => boolean;
};
