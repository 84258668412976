import { ReactComponent as GarbageIcon } from 'app/assets/svgs/ic_Trash.svg';
import { UploadedFile } from 'app/services/types';
import { useDeleteFileMutation, useUploadFileMutation } from 'app/services/upload';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Spinner from '../Spinner';
import { Button } from '../v1/Button';

export interface IInputFilesUploadProps {
  onChange?: (files: UploadedFile[]) => void;
  onDelete?: (fileId: string) => void;
  required?: boolean;
  label: string;
  feedback?: string;
  classes?: string;
  files?: any;
  isInvalid?: boolean;
  readOnly?: boolean;
  filesLocalStorageKey?: string;
  fileTypes?: string;
  path?: string;
}

export default function InputFilesUpload(props: IInputFilesUploadProps) {
  const { required, label, feedback, classes, isInvalid, readOnly, filesLocalStorageKey, fileTypes } = props;

  const [uploadFile, { isLoading: isUploading }] = useUploadFileMutation();
  const [deleteFile, { isLoading: isDeleting }] = useDeleteFileMutation();
  const [files, setFiles] = useState<{ file: any; uploadedFile?: any; fileId: string; isLoading?: boolean }[]>(
    props.files?.length
      ? props.files?.map((e: any) => ({
          file: e,
          uploadedFile: e,
          fileId: _.uniqueId(),
          isLoading: false
        }))
      : []
  );

  const [fileIdError, setFileIdError] = useState(false);

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      setFiles((state: any) => [...state, ...acceptedFiles]);
      onFileUpload(acceptedFiles[0]);
    },
    [files]
  );

  const onDeleteClicked = useCallback(
    async (e: any, file: any) => {
      e.stopPropagation();
      try {
        const selectedFile = files?.find((e) => e.fileId === file?.fileId)!;
        selectedFile.isLoading = true;
        setFiles([...files]);
        await deleteFile(selectedFile?.uploadedFile?.id).unwrap();
        selectedFile.isLoading = false;
        setFiles((state) => state.filter((e) => e.fileId !== file?.fileId));
        props.onDelete && props.onDelete(selectedFile?.uploadedFile?.id);
      } catch {}
    },
    [files, props.onDelete]
  );

  useEffect(() => {
    setFiles(
      props.files?.map((e: any) =>
        e?.fileId
          ? e
          : {
              file: e,
              fileId: _.uniqueId(),
              uploadedFile: e,
              isLoading: false
            }
      )
    );
  }, [props.files]);
  const onFileUpload = useCallback(
    async (acceptedFiles: any) => {
      if (acceptedFiles) {
        const formData = new FormData();
        formData.append('files', acceptedFiles);
        props.path && formData.append('path', props.path);

        const uploadedFile = {
          file: acceptedFiles,
          fileId: _.uniqueId(),
          isLoading: true,
          uploadedFile: null
        };
        setFiles([...files, uploadedFile]);
        try {
          const res = await uploadFile(formData).unwrap();
          uploadedFile.isLoading = false;
          uploadedFile.uploadedFile = res?.at(0);
          setFiles([...files]);
          props.onChange && props.onChange(res);
        } catch {
          setFileIdError(true);
        }
      }
    },
    [files, props.onChange]
  );

  const { getRootProps } = useDropzone({ onDrop, noClick: true });

  return (
    <div className="d-flex flex-wrap gap-3">
      <div
        {...getRootProps()}
        className={`position-relative justify-content-center d-flex flex-column align-items-center border border-dashed p-4 rounded-3 pb-4 ${classes} me-3`}
        style={{ width: '140px', cursor: 'pointer', height: '100px' }}
      >
        {fileTypes ? (
          <>
            <input
              disabled={readOnly || isUploading || isDeleting}
              style={{
                opacity: '0',
                position: 'absolute',
                width: '100%',
                height: '100%',
                cursor: 'pointer'
              }}
              className={isInvalid ? 'is-invalid' : ''}
              type="file"
              accept={fileTypes}
              onChange={(e) => {
                onDrop(e.target.files);
              }}
            />
          </>
        ) : (
          <>
            <input
              disabled={readOnly || isUploading || isDeleting}
              style={{
                opacity: '0',
                position: 'absolute',
                width: '100%',
                height: '100%',
                cursor: 'pointer'
              }}
              className={isInvalid ? 'is-invalid' : ''}
              type="file"
              onChange={(e) => {
                onDrop(e.target.files);
              }}
            />
          </>
        )}

        <span className="text-primary text-center">+ {label}</span>
      </div>
      {files?.map((file, index: number) => (
        <div
          key={index}
          {...getRootProps()}
          className={`position-relative justify-content-center d-flex flex-column align-items-center border border-dashed p-4 rounded-3 w-fit pb-4 ${classes} me-3`}
          style={{ width: '140px', cursor: 'pointer', height: '100px' }}
        >
          <div className="position-absolute top-0 right-0 end-0 translate-middle-x">
            <Button buttonType="text" disabled={file.isLoading || isUploading || isDeleting}>
              <GarbageIcon
                onClick={(e) => onDeleteClicked(e, file)}
                width={15}
                height={15}
                style={readOnly || file.isLoading ? { pointerEvents: 'none' } : {}}
              />
            </Button>
          </div>
          {file ? (
            <div
              className="text-center fs-xs pb-4 mt-4"
              style={{
                textOverflow: 'ellipsis',
                width: '100%',
                overflow: 'hidden',
                whiteSpace: 'nowrap'
              }}
            >
              {file?.uploadedFile?.name ?? file?.file?.name}
            </div>
          ) : (
            <span className="text-primary">+ {label}</span>
          )}
          {file?.isLoading && (
            <div className="w-100">
              <div className="text-center text-muted fs-xs">Uploading</div>
              <Spinner />
            </div>
          )}
          {!required && <div className="text-muted text-center mt-2">(OPTIONAL)</div>}
          <span className="invalid-feedback text-center">{feedback}</span>
        </div>
      ))}
    </div>
  );
}
