export const reasonsForChanges = [
  'Content too easy',
  'Didn’t like tutor',
  'Double booked',
  'Emergency came up',
  'Group too large',
  'Not fully convinced',
  'Sales agent mistake',
  'Student missed trial',
  'Technical issues',
  'Wants a different time/day',
  'Wrong class/year'
];
export const reasonsForCancel = [
  'Break: holiday/pausing temporarily',
  'Content not challenging/not benefiting',
  'Different exam board/course content',
  'Doesn’t need support/too easy',
  'Didn’t like tutor',
  'Didn’t like trial in another subject',
  'Failed to attend trials',
  'No Response / didn’t answer call: post trial',
  'No Response / didn’t answer call: post lessons',
  'Prefers to study independently',
  'Prefers face to face/in-person tuition',
  'Prefers online tuition',
  'Technical issues/difficulties',
  'Wrong year group',
  'Duplicate form',
  'Exams cancelled',
  'Financial constraints',
  'Finished the course/year',
  'Found another tutor/course',
  'Group too large',
  'Invalid customer details',
  'No Response / didn’t answer call: post form',
  'Prefers 1-to-1',
  'Receiving support at school',
  'Sales agent mistake',
  'Student not keen/shy',
  'Subject not required',
  'Suspected spam',
  'Timings/days not convenient',
  'Too much work/pressure on child',
  'Wrong/missing details',
  'Ability/level not suitable',
  'Credit lesson',
  'Switching subjects',
  'Moving Away/Abroad',
  'Getting Help from Parent / Family / Friend',
  'Failed to pay',
  'Subject too hard',
  'Switching classes',
  'Cancelled on awaiting feedback'
];
export const hearAboutUsOptions = {
  blog_article: 'Blog / Article',
  flyer: 'Flyer',
  google_search: 'Google search',
  influencer: 'Influencer',
  news_media: 'News / Media',
  partner_organization: 'Partner Organisation',
  sales_agent: 'Sales Agent',
  school: 'School',
  social_media: 'Social Media',
  through_friend: 'Through a Friend',
  through_existing_sibling: 'Through existing sibling'
};

export const genders = [
  {
    id: 'male',
    name: 'Male'
  },
  {
    id: 'female',
    name: 'Female'
  },
  {
    id: 'preferNotToSay',
    name: 'Prefer not to say'
  }
];
export const socialMediaOptions = ['Facebook', 'Instagram', 'Youtube', 'LinkedIn'];
export const meetingRecordingTypes = [
  { value: 'lesson', label: 'Lesson', key: 'lesson' },
  { value: 'meeting', label: 'Meeting', key: 'meeting' },
  { value: 'tutorInterview', label: 'Tutor Interview', key: 'tutorInterview' },
  { value: 'staffInterview', label: 'Staff Interview', key: 'staffInterview' },
  { value: 'client_call', label: 'Client Call', key: 'client_call' },
  { value: 'tutorial', label: 'Tutorial', key: 'tutorial' },
  { value: 'coaching', label: 'Coaching', key: 'coaching' },
  { value: 'other', label: 'Other', key: 'other' }
];
export const meetingRecordingYoutubeAccounts = [
  { value: 'account01', label: 'Account 01', key: 'account01' },
  { value: 'account02', label: 'Account 02', key: 'account02' },
  { value: 'account03', label: 'Account 03', key: 'account03' },
  { value: 'account04', label: 'Account 04', key: 'account04' },
  { value: 'account05', label: 'Account 05', key: 'account05' },
  { value: 'account06', label: 'Account 06', key: 'account06' },
  { value: 'account07', label: 'Account 07', key: 'account07' },
  { value: 'account08', label: 'Account 08', key: 'account08' },
  { value: 'account09', label: 'Account 09', key: 'account09' },
  { value: 'account10', label: 'Account 10', key: 'account10' }
];
export const meetingRecordingStatus = [
  { value: 'complete', label: 'Complete', key: 'complete' },
  { value: 'incomplete', label: 'Incomplete', key: 'incomplete' },
  { value: 'stuck', label: 'Stuck', key: 'stuck' },
  { value: 'noRecording', label: 'No Recording', key: 'noRecording' }
];

export const markingBlockerReasons: Array<{ label: string; value: string[] }> = [
  // teacher
  {
    label: 'Wrong/missing solutions',
    value: ['teacher', 'Wrong/missing solutions']
  },
  {
    label: 'Wrong/missing questions paper',
    value: ['teacher', 'Wrong/missing questions paper']
  },
  {
    label: 'No mark scheme provided',
    value: ['teacher', 'No mark scheme provided']
  },
  {
    label: 'Homework paper unclear',
    value: ['teacher', 'Homework paper unclear']
  },
  // student
  {
    label: 'Wrong homework submitted',
    value: ['student', 'Wrong homework submitted']
  },
  {
    label: 'Unclear text/photo',
    value: ['student', 'Unclear text/photo']
  },
  {
    label: 'File corrupted',
    value: ['student', 'File corrupted']
  }
];

export const AvailablePaymentDays: AvailablePaymentDay[] = [
  {
    id: '1',
    name: '1st',
    description: '1st of the month',
    value: '1'
  },
  {
    id: '2',
    name: '15th',
    description: '15th of the month',
    value: '15'
  }
];

export enum TeacherCapabilityType {
  TEACH = 'teach',
  SUPPLY = 'supply',
  MARK = 'mark'
}

export enum TeacherCapabilityPriority {
  PRIORITY1 = 'priority1',
  PRIORITY2 = 'priority2',
  PRIORITY3 = 'priority3'
}
export type AvailablePaymentDay = {
  id: string;
  name: string;
  description: string;
  value: string;
};

export const GLOBAL_SETTING = {
  ALLOW_MARK_TEACHER_CAPABILITY: false,
  PAGE_SIZE: 10
};

export const qtsOptions = [
  {
    id: 'qualified',
    name: 'Qualified'
  },
  {
    id: 'training',
    name: 'Training'
  },
  {
    id: 'unqualified',
    name: 'Unqualified'
  }
];

export const replySpeedOptions = [
  {
    id: 'delayed',
    name: 'Delayed'
  },
  {
    id: 'moderate',
    name: 'Moderate'
  },
  {
    id: 'fast',
    name: 'Fast'
  }
];

export const qualificationOptions = [
  {
    id: 'trainee',
    name: 'Trainee'
  },
  {
    id: 'junior',
    name: 'Junior'
  },
  {
    id: 'associate',
    name: 'Associate'
  },
  {
    id: 'seniorAssociate',
    name: 'Senior Associate'
  },
  {
    id: 'senior',
    name: 'Senior'
  }
];
export const hmrcStatusOptions = [
  {
    id: 'inProgress',
    name: 'In progress'
  },
  {
    id: 'sent',
    name: 'Sent'
  },
  {
    id: 'incorrectDates',
    name: 'Incorrect dates'
  }
];

export const companiesHouseStatusOptions = [
  {
    id: 'registered',
    name: 'Registered'
  },
  {
    id: 'delay',
    name: 'Registered-Delay'
  },
  {
    id: 'deregistered',
    name: 'Deregistered'
  },
  {
    id: 'check',
    name: 'Check'
  },
  {
    id: 'problem',
    name: 'Problem'
  }
];
export const parentContractStatusOptions = [
  {
    value: 'sent',
    label: 'Sent'
  },
  {
    value: 'messaged',
    label: 'Not signed - Messaged'
  },
  {
    value: 'called',
    label: 'Not signed - Called'
  },
  {
    value: 'signed',
    label: 'Signed'
  },
  {
    value: 'rejected',
    label: 'Rejected'
  },
  {
    value: 'manual',
    label: 'Manual agreement'
  }
];

export const callReasons = [
  {
    value: 'attendance',
    label: 'Attendance: Student Absent'
  },
  {
    value: 'general',
    label: 'General Issue'
  },
  {
    value: 'sales',
    label: 'Sales'
  },
  {
    value: 'customerService',
    label: 'Customer Service'
  },
  {
    value: 'cancellation',
    label: 'Cancellation'
  }
];

export const attendanceResponse = [
  {
    value: 'coming',
    label: 'Coming'
  },
  {
    value: 'notComing',
    label: 'Not Coming'
  },
  {
    value: 'noAnswer',
    label: 'No Answer'
  }
];

export const generalIssueResponse = [
  {
    value: 'resolved',
    label: 'Resolved'
  },
  {
    value: 'notResolved',
    label: 'Unresolved'
  },
  {
    value: 'noAnswer',
    label: 'No Answer'
  }
];

export const salesCallResponse = [
  {
    value: 'answered',
    label: 'Answered'
  },
  {
    value: 'noAnswer',
    label: 'No Answer'
  },
  {
    value: 'callBack',
    label: 'Requested call back'
  },
  {
    value: 'hungUp',
    label: 'Hung up phone'
  },
  {
    value: 'invalid',
    label: 'Invalid Number'
  },
  {
    value: 'messaged',
    label: 'Messaged'
  }
];

export const homeschooledOptions = [
  {
    value: 'yes',
    label: 'Yes'
  },
  {
    value: 'yesWillBeChanged',
    label: 'Yes, but changing soon'
  },
  {
    value: 'planning',
    label: 'Planning to homeschool'
  }
];

export const defaultPageSize = 50;

export const marketingPreferenceOptions = [
  {
    value: 'yes',
    label: 'Yes'
  },
  {
    value: 'no',
    label: 'No'
  }
];

export enum StudentAbilityType {
  HIGH = 'high',
  ABOVEAVG = 'aboveAverage',
  AVG = 'average',
  BELOWAVG = 'belowAverage',
  STRUGGLES = 'struggles'
}

export const classStreamOptions = [
  {
    value: 'a',
    label: 'A'
  },
  {
    value: 'b',
    label: 'B'
  },
  {
    value: 'c',
    label: 'C'
  },
  {
    value: 'none',
    label: 'None'
  }
];

export const lessonAbilityOptions = [
  { value: 'exceeding', label: 'Exceeding' },
  { value: 'expected', label: 'Expected' },
  { value: 'emerging', label: 'Emerging' }
];

export const followUpOptions = [
  { label: 'Messaged Once', value: 'messagedOnce' },
  { label: 'Messaged Twice', value: 'messagedTwice' },
  { label: 'Called', value: 'called' },
  { label: 'Resolved', value: 'resolved' }
];
