import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { clarity } from 'react-microsoft-clarity';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      }),
      Sentry.replayIntegration()
    ],
    environment: process.env.REACT_APP_NODE_ENV ?? 'development',
    tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_TRACE_RATE || '0.5')
  });
}

if (process.env.REACT_APP_MS_CLARITY) {
 clarity.init(process.env.REACT_APP_MS_CLARITY);
}